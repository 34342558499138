import request from '@/utils/request'

/**
 * 上传文件
 * @param params
 */
export const uploadFileAPI = (params: File) => request.post('/sys/uploadFile', { file: params }, {
  headers: { contentType: 'multipart/form-data' },
  transformRequest: [function (data) {
    const form = new FormData()
    form.append('file', data.file)
    return form
  }]
})

/**
 * 导出|下载文件
 * @param url
 * @param params
 * @param method
 */
export const exportFileAPI = (url: string, params?: any, method: 'get' | 'post' = 'get') => request.request({
  url, params, method, responseType: 'blob',
  transformResponse: [function (data) {
    const url = URL.createObjectURL(data)
    data = {
      url,
      download (fileName: string) {
        const a = document.createElement('a')
        a.href = url
        a.download = fileName
        a.click()
        URL.revokeObjectURL(url)
      }
    }
    return data
  }]
})

/**
 * 获取帮助文档地址
 */
export const getHelpDocumentAPI = () => request.get('/cockpit/getText')

/**
 * 设置帮助文档地址
 */
export const setHelpDocumentAPI = (params: { text: string }) => request.get('/cockpit/setText', { params })
