import { decrypt, encrypt } from '@utils/crypto'
import { RouteLocationRaw, LocationQueryRaw, LocationQuery } from 'vue-router'
import router from '@/router'

/**
 * 全局参数加密
 */
['push', 'replace'].forEach(function (key) {
  const original = router[key]
  router[key] = function (to: RouteLocationRaw) {
    if (typeof to !== 'string' && to?.query) {
      to.query = queryEncrypt(to.query)
    }
    return original.call(this, to)
  }
})

/**
 * 参数加密
 * @param query
 */
export function queryEncrypt (query: LocationQueryRaw): LocationQuery {
  return { v: encrypt(JSON.stringify(query)) }
}

/**
 * 参数解密
 * @param query
 */
export function queryDecrypt (query: LocationQuery): LocationQuery {
  return JSON.parse(decrypt(query.v as string))
}
