import { storage } from '@utils/storage'
import request from '@utils/request'
import qs from 'qs'
import { Pages } from '@/typings'

/**
 * 添加润滑站
 */
export const DeviceForm = {
  id: null as number,
  deviceName: '', // 润滑站名
  deviceStyle: null as number, // 润滑站类型
  images: '', // 工艺图
  addressId: [], // 区域
  theHeartbeat: '', // 站点ID
  communication: '', // 通讯方式
  coordinates: '', // 位置坐标
  port: '', // 端口
  pumpCount: null, // 油泵数量
  remark: '', // 备注
  deviceAddress: '', // 设备位置
  lubricationPoint: '', // 润滑点（润滑站编号）
}
export type DeviceFormType = typeof DeviceForm
export type DeviceType = DeviceFormType & {
  // 通讯状态(1通讯正常2通讯故障)
  stateOfCommunication: number,
  // 设备异常状态(1正常2故障3液位低4低温保护)
  deviceAbnormalType: number,
  // 设备正常状态(1冻结2打油3休止)
  deviceNormalType: number,
  // 区域名称
  addressName: string,
}
export const addDeviceAPI = (params?: DeviceFormType) => request.post('/device/add', params)

/**
 * 修改设备信息
 * @param params
 */
export const modifyDeviceAPI = (params?: DeviceFormType) => request.post('/device/change', params)

/**
 * 按权限获取区域&设备树 （菜单栏）
 */
export const getLeftMenuListAPI = () => request.get('/cockpit/directoryTree')

/**
 * 获取设备列表
 * @param params
 */
export const getDeviceListAPI = (params?: {
  addressId?: number,
  deviceName?: string,
  beginDate?: string,
  endDate?: string,
  deviceId?: number
} & Pages) => request.get('/device/show', { params })

/**
 * 删除设备
 * @param params
 */
export const deleteDeviceAPI = (params: { deviceId: string | number }) => request.get('/device/remove', { params })

/**
 * 获取设备各状态数量
 * @param params
 */
export const getDeviceStartCount = (params?: { floor: number }) => request.get('/device/deviceType', { params })
//
// /**
//  * 误刷信息
//  * @param params
//  */
// export const getFaultCardCountAPI = (params?: { deviceId: number }) => request.get('/wrongOperation/show', { params })
//
// /**
//  * 获取设备被哪个部门使用
//  * @param params
//  */
// export const getUseDepartmentByDeviceAPI = (params?: { deviceId: number }) => request.get('/device/getUse', { params })
