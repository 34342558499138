import interceptorConfig from './config'
import { authorizationCheck, getToken } from '@/utils/auth'
import { RouteLocationNormalized } from 'vue-router'
import store from '@/store'

interface AuthResult {
  // 是否需要登录
  state: boolean,
  // 是否需要跳转页面
  toPath?: string,
  // 是否需要权限认证
  authorization?: boolean
}

/**
 * 认证授权
 * @param to
 */
export async function auth (to: RouteLocationNormalized): Promise<AuthResult> {
  let authResult = authentication(to)
  if (authResult.authorization) {
    authResult = await authorization(to)
  }
  return authResult
}

/**
 * 认证 - 有无登录信息
 * @param to
 */
function authentication (to: RouteLocationNormalized): AuthResult {
  // 获取默认拦截器配置
  let config = interceptorConfig.default
  // 返回的验证结果
  const authResult: AuthResult = { state: true }
  // 获取符合条件的拦截器
  for (const key in interceptorConfig) {
    if (new RegExp(key.replace(/\*/g, '.*') + '$').test(to.path)) {
      config = interceptorConfig[key]
      break
    }
  }
  const token = getToken()
  // 如果需要token
  if (config.token) {
    // 如果有
    if (token) {
      authResult.authorization = true
    } else {
      authResult.state = false
      authResult.toPath = config.notTokenTo
    }
    // 如果不需要
  } else {
    // 如果有
    if (token) {
      authResult.state = false
      authResult.toPath = config.tokenTo
    }
  }
  return authResult
}

/**
 * 授权 - 有无权限信息
 * @param to
 */
async function authorization (to: RouteLocationNormalized): Promise<AuthResult> {
  const authResult: AuthResult = { state: true }
  if (!store.getters['app/userInfo'].id) {
    try {
      // 获取用户信息
      await store.dispatch('app/updateUserInfo')
    } catch (e) {
      // 获取失败直接去错误页
      const code = 403
      const _code = (e as Error)?.message.replace(/.*[code ](.*)/, '$1')
      authResult.state = false
      authResult.toPath = '/error/' + (_code || code)
    }
  }
  // 如果没有权限
  if (!authorizationCheck(to.meta.permission, to.meta.role)) {
    authResult.state = false
    authResult.toPath = '/error/403'
  }
  return authResult
}
