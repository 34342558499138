/* eslint-disable */
export enum EmptyEnum {
  SUCCESS = 'SUCCESS',
  EMPTY = 'Empty',
  EMPTY_VAR = 'EmptyVar',
  LOADING = 'Loading',
  NONE = 'None',
}

export type EmptyInfo = { src?: string, description?: string }

export type EmptyType = { type?: EmptyEnum | string } & EmptyInfo

export declare type EmptyState = string | boolean | EmptyType
