import router from '@/router'
import nProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { auth } from '@/router/interceptor/permission'
import { queryDecrypt } from '@utils/router'
import settings from '@/settings'

// TODO 路由前置守卫
router.beforeEach(async function (to, from, next) {
  nProgress.start()
  document.title = settings.TITLE
  // 授权验证
  const authResult = await auth(to)
  if (authResult.state) {
    next()
  } else {
    nProgress.done()
    next(authResult.toPath)
  }
})

// TODO 路由后置守卫
router.afterEach(function (to) {
  to.query.v && (to.query = queryDecrypt(to.query))
  nProgress.done()
})
