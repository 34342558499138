import { matchUnit } from '@/utils/object-util/match-unit'

type PeriodicCallOption = { fn(...arg): Promise<unknown>, timeout: number, rightAway?: boolean, id?: string }
type PeriodicCallItemKey = string | {()}
type PeriodicCallItem = PeriodicCallOption & { key: PeriodicCallItemKey }
// 周期性执行函数
export class PeriodicCall {
  periodicMap = new Map<string | {()}, PeriodicCallItem>()
  timerMap = new Map<string | {()}, number>()
  constructor (option: PeriodicCallOption[]) {
    const that = this
    option.forEach(function (_item) {
      const item = _item as PeriodicCallItem
      item.key = item.id || item.fn
      that.periodicMap.set(item.key, item)
    })
    this.init()
  }

  init () {
    const that = this
    this.periodicMap.forEach((item) => {
      if (item.rightAway !== false) {
        that.periodicFn(item, true)
      } else {
        this.timerMap.set(item.key, setTimeout(() => {
          this.periodicFn(item, true)
        }, item.timeout))
      }
    })
  }

  periodicFn (periodic: PeriodicCallItem, firstCall?: boolean) {
    this.timerMap.get(periodic.key) && clearTimeout(this.timerMap.get(periodic.key))
    periodic.fn(firstCall).finally(() => {
      this.timerMap.set(periodic.key, setTimeout(() => {
        this.periodicFn(periodic, false)
      }, periodic.timeout))
    })
  }

  clear (id?: PeriodicCallItemKey) {
    if (id) {
      clearTimeout(this.timerMap.get(id))
      this.periodicMap.delete(id)
      this.timerMap.delete(id)
    } else {
      this.timerMap.forEach((item) => {
        clearTimeout(item)
      })
      this.periodicMap.clear()
      this.timerMap.clear()
    }
  }

  run (id?: PeriodicCallItemKey, loading = false) {
    if (id) {
      this.periodicFn(this.periodicMap.get(id), loading)
    } else {
      this.periodicMap.forEach((item) => {
        this.periodicFn(item, loading)
      })
    }
  }
}

// deep: 允许添加新的属性, object: 深度合并对象, array: 深度合并数组
declare type assignDeepOption = { deep?: boolean, object?: boolean, array?: boolean }
export class ObjectUtil {
  /**
   * 获取两个对象不同的属性
   * @param reference
   * @param arg
   */
  static diff (reference: Record<string, any>, arg: Record<string, any>) {
    const ret = {} as any
    for (const key in reference) {
      if (arg[key] !== reference[key]) {
        ret[key] = arg[key]
      }
    }
    return ret
  }

  /**
   * 延迟
   * @param delay
   */
  static sleep (delay: number) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(undefined)
      }, delay)
    })
  }

  /**
   * 根据地址获取源数据中的值
   * @param source
   * @param address
   * @param char
   */
  static getAttr (source: any, address: string, char = '/'): any {
    const tier = address.split(char)
    try {
      tier.forEach(key => {
        source = source[key]
      })
      return source
    } catch (e) {
      return undefined
    }
  }

  /**
   * 遍历对象或数组
   * @param source
   * @param callback
   */
  static forEach (source: any, callback: (value: any, key: string, index: number) => void): void {
    let index = 0
    for (const key in (<Record<string, any>>source)) {
      callback(source[key], key, index)
      index++
    }
  }

  /**
   * 判断参数是否为null|undefined|''
   * @param value
   */
  static isNotEmpty (value: unknown): boolean {
    return value === 0 || !!value
  }

  /**
   * 判断参数是否为null|undefined|''
   * @param value
   */
  static isEmpty (value: unknown): boolean {
    return !this.isNotEmpty(value)
  }

  /**
   * 如果value不为空返回value否则返回arg
   * @param value
   * @param arg
   */
  static isEmptyToVal<T, P> (value: T, arg: P): T | P {
    const result = this.isNotEmpty(value)
    return result ? value : arg
  }

  /**
   * 根据source更新target中的属性值
   * @param target
   * @param source
   * @param template
   */
  static updateVal (target: any, source: any, template?: any): void {
    !template && (template = target)
    for (const key in template) {
      target[key] = source[key]
    }
  }

  /**
   * 拷贝
   * @param arg
   */
  static copy<T> (arg: T): T {
    return JSON.parse(JSON.stringify(arg))
  }

  /**
   * 深度拷贝
   * @param from
   */
  static deepCopy<T> (from: T): T {
    return this._deepCopy_(from)
  }

  /**
   * deepCopy的递归函数
   * @param from
   * @param to
   * @private
   */
  private static _deepCopy_ (from: any, to?: any): any {
    if (Array.isArray(from)) {
      to = []
      from.forEach((item: unknown, index: number) => {
        to[index] = typeof from[index] === 'object' ? this._deepCopy_(from[index], to[index]) : from[index]
      })
    } else {
      if (!this.isObject(from)) {
        return from
      }
      to = {}
      for (const key in from) {
        to[key] = typeof from[key] === 'object' ? this._deepCopy_(from[key], to[key]) : from[key]
      }
    }
    return to
  }

  /**
   * 合并两个对象
   * @param target
   * @param source
   * @param option
   */
  static assignDeep<T, U> (target: T, source: U, option?: assignDeepOption): T & U {
    // deep: 允许添加新的属性, object: 深度合并对象, array: 深度合并数组
    const _option_ = Object.assign({
      deep: true,
      object: true,
      array: true
    }, option || {})
    this._assignDeep_(target, source, _option_)
    return <T & U>target
  }

  /**
   * assignDeep 的递归函数
   * @param target
   * @param source
   * @param option
   * @private
   */
  private static _assignDeep_ (target: any, source: any, option: assignDeepOption): void {
    for (const key in source) {
      const val = source[key]
      if ((this.isObject(val) && this.isObject(target[key]) && option.object) || (Array.isArray(val) && Array.isArray(target[key]) && option.array)) {
        this._assignDeep_(target[key], val, option)
      } else {
        if (option.deep || target[key] !== undefined) {
          target[key] = val
        }
      }
    }
  }

  static matchUnit = matchUnit

  static isBoolean (arg: unknown): arg is boolean {
    return Object.prototype.toString.call(arg) === '[object Boolean]'
  }

  static isString (arg: unknown): arg is string {
    return Object.prototype.toString.call(arg) === '[object String]'
  }

  static isNumber (arg: unknown): arg is number {
    return Object.prototype.toString.call(arg) === '[object Number]'
  }

  static isObject (arg: unknown): arg is Global.Object {
    return Object.prototype.toString.call(arg) === '[object Object]'
  }

  static isDate (arg: unknown): arg is Date {
    return Object.prototype.toString.call(arg) === '[object Date]'
  }

  static isUndefined (arg: unknown): arg is undefined {
    return typeof arg === 'undefined'
  }
}

export class NumberUtil {
  /**
   * 保留最大小数位数
   * @param value
   * @param fractionDigits
   */
  static toFixed (value: string | number, fractionDigits: number): number {
    return Number(Number(value).toFixed(fractionDigits))
  }
}
