import { createModule } from '@/store'
import { User } from '@/typings/user'
import { getUserInfoAPI } from '@/api/auth'
import { removeToken } from '@utils/auth'

export default createModule({
  namespaced: true,
  state: {
    // 用户信息
    userInfo: {} as User,
    realtimeAlarm: new Map(),
    alarmCount: null,
  },
  mutations: {
    setUserInfo (state, value) { state.userInfo = value },
    setRealtimeAlarm (state, value) { state.realtimeAlarm = value },
    setAlarmCount (state, value) { state.alarmCount = value },
  },
  actions: {
    // TODO 更新用户信息
    updateUserInfo ({ commit }) {
      return new Promise((resolve, reject) => {
        getUserInfoAPI().then(res => {
          if (res.result) {
            res.result().then(async function (rdata) {
              const { data, auth } = rdata.payload
              // data.role = { 普通用户: '1', 管理员: '2', 超级管理员: '0' }[data.identity]
              data.role = auth.id.toString()
              // data.roleName = role?.roleName || '--'
              data.roleName = auth.name
              data.permission = new Set()
              commit('setUserInfo', data)
              resolve({ state: true })
            })
          } else {
            // 模拟用户信息
            const data = res.data.payload.data
            data.role = '1'
            data.permission = new Set()
            commit('setUserInfo', data)
            resolve({ state: true })
          }
        }).catch(function () {
          removeToken()
          reject(Error())
        })
      })
    }
  },
  getters: {
    userInfo: state => state.userInfo,
    realtimeAlarm: state => state.realtimeAlarm,
    alarmCount: state => state.alarmCount,
  },
})
