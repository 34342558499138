import { createModule } from '@/store'

export default createModule({
  namespaced: true,
  state: {
    // 侧边栏展开/收缩状态
    collapse: false
  },
  mutations: {
    // 设置展开和收缩状态
    setCollapse (state, value) {
      state.collapse = value
    },
    // 切换展开/收缩状态
    collapseToggle (state) {
      state.collapse = !state.collapse
    }
  },
  actions: {
  },
  getters: {
    collapse: state => state.collapse
  },
})
