import { RouteRecordRaw } from 'vue-router'
import LayoutView from '@/layout/LayoutView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/overview', component: LayoutView, redirect: '/overview/index',
    children: [
      {
        path: 'index', name: 'Overviews',
        meta: { title: '总览' },
        component: () => import('@/views/overview/OverviewView.vue'),
      }
    ]
  },
  {
    path: '/device-list', component: LayoutView,
    meta: { title: '设备展示' },
    children: [
      {
        path: ':id', name: 'Device',
        meta: { title: '润滑报表' },
        component: () => import('@/views/device-data/DeviceData.vue'),
      }
    ]
  },
  {
    path: '/report', component: LayoutView, redirect: '/report/index',
    children: [
      {
        path: 'index', name: 'Report',
        meta: { title: '润滑报表' },
        component: () => import('@/views/report/DeviceReport.vue'),
      }
    ]
  },
  {
    path: '/alarm', component: LayoutView, redirect: '/alarm/index',
    children: [
      {
        path: 'index', name: 'Alarm',
        meta: { title: '报警记录' },
        component: () => import('@/views/alarm/AlarmView.vue'),
      }
    ]
  },
  {
    path: '/video', component: LayoutView, redirect: '/video/index',
    children: [
      {
        path: 'index', name: 'Video',
        meta: { title: '视频监控' },
        component: () => import('@/views/video/VideoManagement.vue'),
      }
    ]
  },
  {
    path: '/management', component: LayoutView,
    meta: { title: '后台管理', role: ['2'] },
    children: [
      {
        path: 'device', name: 'DeviceManagement',
        meta: { title: '润滑站管理', role: ['1'] },
        component: () => import('@/views/backstage/DeviceManagement.vue'),
      },
      {
        path: 'hierarchy', name: 'HierarchyManagement',
        meta: { title: '区域管理', role: ['1'] },
        component: () => import('@/views/backstage/HierarchyManagement.vue'),
      },
      {
        path: 'user', name: 'UserManage',
        meta: { title: '人员管理', role: ['2'] },
        component: () => import('@/views/backstage/UserManage.vue'),
      },
      {
        path: 'alarm-push', name: 'AlarmPush',
        meta: { title: '报警推送', role: ['1'] },
        component: () => import('@views/backstage/alarm-push/AlarmPush.vue'),
      },
      {
        path: 'role', name: 'RoleManage',
        meta: { title: '权限管理', role: ['1'] },
        component: () => import('@/views/backstage/RoleManage.vue'),
      },
    ]
  },
  {
    path: '/log', component: LayoutView, redirect: '/log/index',
    children: [
      {
        path: 'index', name: 'OperationLog',
        meta: { title: '操作日志' },
        component: () => import('@/views/OperationLog.vue'),
      }
    ]
  },
  {
    path: '/user', component: LayoutView, redirect: '/user/info',
    meta: { isMenu: false },
    children: [
      {
        path: 'info', name: 'UserInfo',
        meta: { title: '用户信息' },
        component: () => import('@/views/user/UserInfo.vue'),
      }
    ]
  },
]

export default { routes, menu: true }
