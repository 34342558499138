import { menuRoutes } from '@/router'
import { RouteRecordRaw } from 'vue-router'
import { ObjectUtil } from '@utils/object-util'
import { authorizationCheck } from '@utils/auth'

let menuList = null

/**
 * 获取菜单列表
 */
export function getMenuList (): RouteRecordRaw[] {
  !menuList && (menuList = formatMenu(ObjectUtil.copy(menuRoutes)))
  return menuListFilter(ObjectUtil.copy(menuList))
}

/**
 * 根据权限筛选菜单
 * @param childrenMenu
 */
function menuListFilter (childrenMenu: RouteRecordRaw[]) {
  return childrenMenu.filter(function (menu) {
    if (authorizationCheck(menu.meta?.permission, menu.meta?.role)) {
      if (menu.children?.length) {
        menu.children = menuListFilter(menu.children)
      }
      return true
    } else {
      return false
    }
  })
}

/**
 * 格式化菜单
 * 把路由对象格式化成菜单对象
 * @param childrenMenu
 * @param parentMenu
 */
export function formatMenu (childrenMenu: RouteRecordRaw[], parentMenu?: RouteRecordRaw) {
  for (let i = 0, len = childrenMenu.length; i < len; i++) {
    const menu = childrenMenu[i]
    if (parentMenu) {
      // 拼接父级地址
      menu.path = parentMenu.path + '/' + menu.path
      // 继承父级权限
      if (!menu.meta?.role && parentMenu.meta?.role) {
        menu.meta.role = parentMenu.meta.role
      }
      if (!menu.meta?.permission && parentMenu.meta?.permission) {
        menu.meta.permission = parentMenu.meta.permission
      }
    }
    if (menu.children && menu.children?.length > 0) {
      // 提前二级菜单到一级
      if (menu.children.length === 1 && menu.children[0].path === 'index') {
        const childMenu = menu.children[0]
        childMenu.path = menu.path + '/' + childMenu.path
        childrenMenu[i] = childMenu
      } else {
        formatMenu(menu.children, menu)
      }
    }
  }
  return childrenMenu
}
