import { RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/', redirect: '/overview'
  },
  {
    path: '/blankPage', name: 'blankPage',
    component: () => import('@/views/blankPage.vue')
  },
  {
    path: '/login', name: 'Login',
    component: () => import('@views/login/LoginView.vue')
  }
]

export default { routes }
