import { defineStore } from 'pinia'

export const useCollapseStore = defineStore('sideCollapse', {
  state: () => ({
    collapse: false
  }),
  actions: {
    toggle () {
      this.collapse = !this.collapse
    },
    setCollapse (collapse) {
      this.collapse = collapse
    }
  }
})
