import axios from 'axios'
import settings from '@/settings'
import { ElMessage } from 'element-plus'
import { getToken, removeToken } from '@/utils/auth'
import router from '@/router'

type Pending = { time: number, count: number }

export type Payload<T> = {
  code: number,
  message?: string,
  payload: T & Global.Object
}

declare module 'axios' {
  export interface AxiosResponse<T> {
    result (): Promise<Payload<T>>
  }
}

// TODO 创建请求实例
const request = axios.create({
  // baseURL: settings.BASE_API,
  baseURL: process.env.NODE_ENV === 'production' ? settings.BASE_API : '/dev',
  timeout: 120000
})

// TODO 初始化token
const token = getToken()
token && (request.defaults.headers.common[settings.HEADERS_TOKEN_KEY] = token)

// TODO 请求拦截器
// 网络请求记录
const pending: Record<string, Pending> = {}
const CancelToken = axios.CancelToken
request.interceptors.request.use(
  config => {
    // 验证是否需要拦截
    // 判断请求地址,不拦截非'/'开头的请求,如其他服务器地址'http://www.xxx.com/'
    if (config.url && config.url.charAt(0) !== '/') return config
    if (config.headers.noCancel) {
      delete config.headers.noCancel
      return config
    }
    // 生成记录key
    const key = `${config.url}&${config.method}&${JSON.stringify(config.data)}`
    // 设置重复请求
    config.cancelToken = new CancelToken(c => {
      // 如果已经被记录,验证是否可再次请求
      if (pending[key]) {
        // 如果未超时,拦截请求,并更新拦截时间,否则删除记录
        if (Date.now() - pending[key].time <= 1000) {
          // 连续已发起请求，给出警告
          if (++pending[key].count >= 10) {
            ElMessage.warning('您的操作过快，请不要频繁操作')
          }
          // 连续发起请求，取消重复请求
          if (++pending[key].count >= 20) {
            c('Cancelled your repeated request')
            ElMessage.error('您频繁请求过多，请求已拦截')
          }
          // 更新时间戳
          pending[key].time = Date.now()
        } else {
          // 删除对应的请求记录，重新发起请求
          delete pending[key]
        }
      }
      if (!pending[key]) {
        // 记录当前的请求
        // time 最近触发时间，count 拦截次数
        pending[key] = {
          time: Date.now(),
          count: 0
        }
      }
    })
    return config
  },
  error => {
    return Promise.reject(error)
  },
)

// TODO 响应拦截器
request.interceptors.response.use(
  response => {
    // 如果token失效或无token
    if (response.data.code === settings.TOKEN_FAILURE_CODE) {
      // 如果无token,跳转到登陆,如果有则清除并给出提示
      if (getToken()) {
        removeToken()
        ElMessage.warning('登录信息已失效')
      }
      router.replace({ name: 'Login' })
    }
    response.result = function () {
      return new Promise((resolve, reject) => {
        switch (response.data.code) {
          case 200:
            resolve(response.data)
            break
          case settings.TOKEN_FAILURE_CODE:
            reject(response.data)
            break
          default:
            reject(response.data)
            ElMessage.warning(response.data.message)
            break
        }
      })
    }
    return response
  },
  error => {
    console.log(error)
    if (error.message.indexOf('timeout') > -1) {
      ElMessage.warning('请求超时，请稍后重试')
    } else {
      const code = error.message.replace(/^.*[code] (.*)$/, '$1')
      switch (Number(code)) {
        case 400:
          ElMessage.warning('请求参数有误，400')
          break
        case 404:
          ElMessage.warning('您访问的资源不存在，404')
          break
        case 405:
          ElMessage.warning('请求方法不能被用于请求相应的资源，405')
          break
        case 415:
          ElMessage.warning('请求不支持该媒体类型，415')
          break
        case 500:
          ElMessage.error('请求服务器错误')
          break
        case 403:
          ElMessage.warning('服务器拒绝了您的请求，403')
          break
        default:
          if (code > 500) {
            ElMessage.error('请求服务器出现异常，' + code)
          }
      }
    }
    return Promise.reject(error)
  },
)

export default request
