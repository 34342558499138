import { Directive } from 'vue'

function isShow (el: HTMLElement): boolean {
  if (el.style.display === 'none') {
    return false
  } else if (el !== document.body) {
    return isShow(el.parentNode as HTMLElement)
  } else {
    return true
  }
}
// 点击空白处触发指令
const directive: Directive = {
  mounted (el, binding) {
    function click (e: MouseEvent) {
      // 如果该元素以不存在，则销毁监听
      if (document.body.contains(el)) {
        // 如果自身和父级的display不为none，且鼠标点击的不是自身或自身的子节点
        if (isShow(el) && el !== e.target && !el.contains(e.target as Node)) {
          binding.value && binding.value()
        }
      } else {
        document.removeEventListener('click', click)
      }
    }

    document.addEventListener('click', click)
  }
}

export default directive
