import { Directive } from 'vue'
import { authorizationCheck } from '@utils/auth'

/*
 权限指令
 使用方法：
 arg1: 权限ID，arg2：角色ID
 v-permission="[number|string|number[]|string[], number[]|string[]]"
 */
const directive: Directive = {
  mounted (el, binding) {
    const [itemPermission = '', itemRole = []] = binding.value
    const authResult = authorizationCheck(itemPermission, itemRole.map(item => item.toString()))
    if (!authResult) {
      el.remove()
    }
  }
}

export default directive
