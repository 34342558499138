exports.env = function () {
  return {
    // 开发环境变量
    // 修改BASE_API需要重跑
    development: {
      BASE_API: 'http://192.168.1.204:8080/aote',
      BASE_API2: 'http://192.168.1.204:6912',

      // BASE_API: 'https://lub.yueshichuandong.cn/aote',
      // BASE_API2: 'https://lub.yueshichuandong.cn/weixin',
      WEBSOCKET: 'wss://lub.yueshichuandong.cn/aote/websocket/',
      IMAGE_PREFIX: 'https://lub.yueshichuandong.cn/',
    },
    // 生产环境变量
    production: {
      BASE_API: 'https://lub.yueshichuandong.cn/aote',
      BASE_API2: 'https://lub.yueshichuandong.cn/weixin',
      WEBSOCKET: 'wss://lub.yueshichuandong.cn/aote/websocket/',
      IMAGE_PREFIX: 'https://lub.yueshichuandong.cn/'
    }
  }
}
